class LandingPageRow {
    widgetType:string="";
    name:string="";
    theme:number=0;
    fullWidth:boolean=false;
    marginTopPercent:number=6;
    marginBottomPercent:number=6;
    showBackgroundImage:boolean=true;
    backgroundImage="";
    heading="";
    bodyText="";
    headingType=2;
    widgets:LandingPageWidget[]=[];
    [key:string]:any;

    getFormConfig(editor:LandingPageEditor, formConfig:ZIDXFormConfig){
        // console.log("displayForm row:", this);
        let field=new ZIDXFormField();
        field.name="rowIndex";
        field.type=ZIDXFormFieldType.HIDDEN;
        formConfig.formFields.push(field);

        field=new ZIDXFormField();
        field.name="name";
        field.label="Row Name";
        field.type=ZIDXFormFieldType.TEXT;
        formConfig.formFields.push(field);

        let imageField=new ZIDXImageFormField();
        imageField.sizes="200x150";
        imageField.downloadPath="/upload/";
        imageField.uploadPath="/upload/";
        imageField.name="backgroundImage";
        imageField.label="Background Image";
        imageField.multiple=true;
        formConfig.formFields.push(imageField);

        field=new ZIDXFormField();
        field.name="heading";
        field.label="Heading";
        field.type=ZIDXFormFieldType.TEXT;
        formConfig.formFields.push(field);

        let htmlField=new ZIDXHTMLFormField();
        htmlField.width="100%";
        htmlField.height="300px";
        htmlField.name="bodyText";
        htmlField.label="Body Text";
        formConfig.formFields.push(htmlField);


        field=new ZIDXFormField();
        field.name="widgetType";
        field.label="Widget Type";
        field.type=ZIDXFormFieldType.SELECT;
        for(let i=0;i<editor.widgetTypes.length;i++){
            let type=editor.widgetTypes[i];
            field.values.push(new ZIDXFormFieldValue(type.label, type.value));
        }
        formConfig.formFields.push(field);
    }
    saveForm(editor:LandingPageEditor, data:any){
        let rowIndex=parseInt(data.rowIndex);
        let sortRowIndex=rowIndex;
        let rows=ZIDX.$(".zidxPageActive tbody tr").length;
        if(rowIndex==-1){
            sortRowIndex=rows;
        }
        // update class fields
        for(var field in data){
            if(this.hasOwnProperty(field)){
                if(typeof this[field]=="number"){
                    this[field]=parseFloat(data[field]);
                }else if(typeof this[field]=="boolean"){
                    if(data[field]=="1") {
                        this[field]=true;
                    }else{
                        this[field]=false;
                    }
                }else if(typeof this[field]=="string"){
                    this[field]=data[field];
                }
            }
        }

        // console.log("saveForm", data.row_name, sortRowIndex, rowIndex, this);
        // update list view
        let rowHTML=this.renderRowHTML(editor);
        if(rowIndex==-1) {
            // insert
            ZIDX.$(".zidxPageActive tbody").append('<tr id="'+sortRowIndex+'">'+rowHTML+'</tr>');
            editor.landingPage.rows.push(this);
            editor.updateRowIndices();
            editor.setupSortable();
        }else{
            // update
            ZIDX.$(".zidxPageActive tbody tr")[sortRowIndex].innerHTML=rowHTML;
        }
    }
    renderRowHTML(editor:LandingPageEditor){
        let adminMenu='<a href="#" class="zidxScriptSort zidxDisableSPALink sortTableID_handle" style=" font-size:150%;cursor:move; float:left; display:inline-block; user-select:none; margin-right:20px;">&#x21C5</a> ' +
            '<a href="#" class="zidxScriptEdit zidxDisableSPALink" style=" font-size:150%;  margin-right:20px;"><i class="fa fa-cog"></i></a> ' +
            '<a href="#" class="zidxScriptDelete zidxDisableSPALink" style=" font-size:150%;"><i class="fa fa-trash"></i></a>';
        return '<td>'+this.name+'</td><td>'+this.heading+'</td><td>'+editor.getWidgetName(this.widgetType)+'</td><td>'+adminMenu+'</td>';

    }
}